import uniqid from 'uniqid'

import './Skills.css'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import EmailIcon from '@mui/icons-material/Email'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { skills, skills2 } from '../../portfolio'

const Skills = () => {
  if (!skills.length) return null

  return (
    <section className='section skills' id='aboutme'>
      <h2 className='section__title'>About Me</h2>

      <div className='about__contact center testClass'>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://github.com/garizola'
          aria-label='github'
          className='link link--icon'
        >
          <GitHubIcon />
        </a>

        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.linkedin.com/in/giovanniarizola/'
          aria-label='linkedin'
          className='link link--icon'
        >
          <LinkedInIcon />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://contact-giovanni.vercel.app/'
          aria-label='email'
          className='link link--icon'
        >
          <EmailIcon />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://firebasestorage.googleapis.com/v0/b/ga-storage.appspot.com/o/project-images%2Fgiovanni-arizola-resume.pdf?alt=media&token=438b479a-8a42-4967-a9b5-a258eff7f200'
          aria-label='Resume'
          className='link link--icon'
        >
          <SummarizeIcon />
        </a>
      </div>
      <div className='about__desc3'>
        <p>
          My name is Giovanni Arizola, and I am a software developer who
          specializes in web applications. I am pursuing a double major in
          Informatics and Media Arts and Science with a specialization in web
          development. My anticipated graduation is in May 2024. I have a
          passion for learning all aspects of web and mobile development. I am
          eager to broaden my skill set and create more sophisticated and
          impactful applications!
        </p>
      </div>

      <h2 className='section__title'>Skilled In:</h2>
      <ul className='skills__list'>
        {skills.map((skill) => (
          <li
            key={uniqid()}
            className='skills__list-item btn--skills btn--plain'
          >
            {skill}
          </li>
        ))}
      </ul>

      <h2 className='section__title pt'>Familiar With:</h2>
      <ul className='skills__list'>
        {skills2.map((skill) => (
          <li
            key={uniqid()}
            className='skills__list-item btn--skills btn--plain'
          >
            {skill}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Skills
