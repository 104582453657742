import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './ProjectContainer.css'

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <a
      target='_blank'
      rel='noreferrer'
      href={project.livePreview}
      aria-label='live preview'
    >
      <h3 className='projs-title'>{project.name}</h3>
    </a>

    <p className='project__description'>{project.description}</p>

    <div className='imgContainer'>
      {project.img && (
        <a
          target='_blank'
          rel='noreferrer'
          href={project.livePreview}
          aria-label='live preview'
        >
          <img alt='test' src={project.img} className='projIMG' />
        </a>
      )}
    </div>
    {project.stack && (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {project.sourceCode && (
      <a
        target='_blank'
        rel='noreferrer'
        href={project.sourceCode}
        aria-label='source code'
        className='link link--icon'
      >
        <GitHubIcon />
      </a>
    )}

    {project.livePreview && (
      <a
        target='_blank'
        rel='noreferrer'
        href={project.livePreview}
        aria-label='live preview'
        className='link link--icon'
      >
        <LaunchIcon />
      </a>
    )}
  </div>
)

export default ProjectContainer
