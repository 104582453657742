import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import EmailIcon from '@mui/icons-material/Email'
import SummarizeIcon from '@mui/icons-material/Summarize'
import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, social } = about

  return (
    <div id='hero' className='about center'>
      <h1>
        <span className='about__name'>{name}</span>
      </h1>
      <h2 className='about__role'>{role}</h2>

      <div className='about__desc '>
        <p className='arrow'>
          Scroll to see my projects and read more about me
        </p>
        <a href='#projects' className='link link--icon go-down'>
          <ArrowDownwardIcon fontSize='large' />
        </a>
      </div>

      <div className='about__contact center'>
        {social && (
          <>
            {social.github && (
              <a
                target='_blank'
                rel='noreferrer'
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                target='_blank'
                rel='noreferrer'
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}
            <a
              target='_blank'
              rel='noreferrer'
              href='https://contact-giovanni.vercel.app/'
              aria-label='email'
              className='link link--icon'
            >
              <EmailIcon />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://firebasestorage.googleapis.com/v0/b/ga-storage.appspot.com/o/project-images%2Fgiovanni-arizola-resume.pdf?alt=media&token=438b479a-8a42-4967-a9b5-a258eff7f200'
              aria-label='Resume'
              className='link link--icon'
            >
              <SummarizeIcon />
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default About
