import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = () => {
  const { homepage, title } = header

  return (
    <header className='header center'>
      <a href='#hero'>
        <h3>GA</h3>
      </a>

      <Navbar />
    </header>
  )
}

export default Header
