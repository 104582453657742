import uniqid from 'uniqid'
import { projects } from '../../portfolio'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'

const Projects = () => {
  if (!projects.length) return null

  return (
    <section id='projects' className='section projects'>
      <h2 className='section__title projs__title'>Projects</h2>

      <div className='projects__grid'>
        {projects.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
      </div>
      <div className='about__desc2'>
        <p>
          These are just a few of my projects. Through coursework and other
          personal interests, I have built much more. Check out my public
          repos&nbsp;
          <a
            target='_blank'
            rel='noreferrer'
            href='https://github.com/garizola'
            aria-label='github'
            className='link link--icon hereLink'
          >
            here
          </a>
          !
        </p>
      </div>
    </section>
  )
}

export default Projects
